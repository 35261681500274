import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ROLE_ADMIN } from '../../shared/constants';

export interface Menu {
  title: string;
  url: string;
  role?: string;
}

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private menuSubject = new ReplaySubject<Menu>(1);
  currentMenu$ = this.menuSubject.asObservable();

  mainMenus: Menu[] = [
    {
      title: 'menu.targeting',
      url: '/targeting'
    },
    {
      title: 'menu.segments',
      url: '/segments'
    },
    {
      title: 'menu.targetingGroupPotentials',
      url: '/targetingGroupPotentials'
    },
    {
      title: 'menu.segment-list',
      url: '/segment-list',
      role: ROLE_ADMIN
    },
    {
      title: 'menu.admin',
      url: '/admin',
      role: ROLE_ADMIN
    }
  ];

  setMenu(menu: Menu) {
    this.menuSubject.next(menu);
  }
}
